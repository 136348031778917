import React from 'react';
import Footer from './Footer';
import 'normalize.css';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';




const SiteBorderStyles = styled.div`

  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
`;

const ContentStyles = styled.div`
  background: black;
`;

export default function Layout({ children }) {
    return (
        <div>
            <Typography/>
            <GlobalStyles/>
            <SiteBorderStyles>
            <ContentStyles>
                { children }
              <Footer/>
            </ContentStyles>
            </SiteBorderStyles>
        </div>
    );
}
