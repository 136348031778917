import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
  }

  html {
  box-sizing: border-box;
  background-color: black;
  font-size: 10px;

}
*, *:before, *:after {
  box-sizing: inherit;
}

  body {
    font-size: 2rem;
    max-width: 1024px;
    margin: 0 auto;
  }
  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }


  body::-webkit-scrollbar-thumb {
    background-color: var(--grey) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }
  hr {
    border: 0;
    height: 8px;

  }
  img {
    max-width: 100%;
  }
   .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  20% {opacity: 0.3}
  80% {opacity: 0.6;}

  100% {opacity: 1;}
  }
  @keyframes fadeIn {
   0% {opacity: 0;}
   80% {opacity: 0.6;}
   100% {opacity: 1;}
  }

    .zoomIn {
    -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes zoomIn {
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  50% {
  opacity: 1;
  }
  }
  @keyframes zoomIn {
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  50% {
  opacity: 0.5;
  }
  100% {
  opacity: 1;
  }

}
`;

export default GlobalStyles;
