import React from 'react';
import styled from 'styled-components';


const FooterStyles = styled.nav`
  margin 5em auto;
  p {
   text-align: center;
  }

`;

export default function Footer() {
    return (
        <FooterStyles>
            <p> &copy; All rights reserved </p>
            <p> Maia Forster {new Date().getFullYear() }</p>
        </FooterStyles>
    );
}
